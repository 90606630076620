var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _vm.companies != null && !_vm.api.isLoading
        ? _c("v-data-table", {
            attrs: {
              search: _vm.companySearch,
              items: _vm.companies,
              headers: this.companiesHeader,
              "loading-text": "Data loading...",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", height: "auto" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-5" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Search company's name",
                                          clearable: "",
                                          filled: "",
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.companySearch,
                                          callback: function ($$v) {
                                            _vm.companySearch = $$v
                                          },
                                          expression: "companySearch",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.companies.indexOf(item) + 1) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "PageCompaniesDetail",
                              params: { id: item.company_id },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4168226282
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }