var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.allowedRoles.some(function (role) {
        return this$1.$store.getters.getRole.includes(role)
      })
        ? _c(
            "v-container",
            {},
            [
              _c(
                "v-toolbar",
                { staticClass: "pa-4", attrs: { flat: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.companyTab,
                        callback: function ($$v) {
                          _vm.companyTab = $$v
                        },
                        expression: "companyTab",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            to: "#valid",
                          },
                        },
                        [
                          _c("v-tab", { attrs: { to: "#valid" } }, [
                            _vm._v(" Valid "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            to: "#invalid",
                          },
                        },
                        [
                          _c("v-tab", { attrs: { to: "#invalid" } }, [
                            _vm._v(" Invalid "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            to: "#unknown",
                          },
                        },
                        [
                          _c("v-tab", { attrs: { to: "#unknown" } }, [
                            _vm._v(" Unknown "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            to: "#internal",
                          },
                        },
                        [
                          _c("v-tab", { attrs: { to: "#internal" } }, [
                            _vm._v(" Internal "),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            to: { name: "PageCompaniesCreate" },
                          },
                        },
                        [_vm._v(" New ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.companyTab,
                    callback: function ($$v) {
                      _vm.companyTab = $$v
                    },
                    expression: "companyTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-5", attrs: { id: "valid", flat: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "d-flex justify-center",
                              model: {
                                value: _vm.subscriptionTab,
                                callback: function ($$v) {
                                  _vm.subscriptionTab = $$v
                                },
                                expression: "subscriptionTab",
                              },
                            },
                            [
                              _c("v-tab", [_vm._v(" Active ")]),
                              _c("v-tab", [_vm._v(" Expired ")]),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.subscriptionTab,
                                callback: function ($$v) {
                                  _vm.subscriptionTab = $$v
                                },
                                expression: "subscriptionTab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c("PageCompaniesListSubscription", {
                                    attrs: { status: "Active" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                [
                                  _c("PageCompaniesListSubscription", {
                                    attrs: { status: "Expired" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-5", attrs: { id: "invalid" } },
                    [
                      _c("PageCompaniesListVerification", {
                        attrs: { verification: "invalid" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-5", attrs: { id: "unknown" } },
                    [
                      _c("PageCompaniesListVerification", {
                        attrs: { verification: "unknown" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pa-5", attrs: { id: "internal" } },
                    [
                      _c("PageCompaniesListVerification", {
                        attrs: { verification: "internal" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("v-container", [
            _c(
              "div",
              [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-tabs",
                      {
                        staticClass: "d-flex justify-center",
                        model: {
                          value: _vm.subscriptionTab,
                          callback: function ($$v) {
                            _vm.subscriptionTab = $$v
                          },
                          expression: "subscriptionTab",
                        },
                      },
                      [
                        _c("v-tab", [_vm._v(" Active ")]),
                        _c("v-tab", [_vm._v(" Expired ")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.subscriptionTab,
                      callback: function ($$v) {
                        _vm.subscriptionTab = $$v
                      },
                      expression: "subscriptionTab",
                    },
                  },
                  [
                    _c(
                      "v-tab-item",
                      [
                        _c("PageCompaniesListSubscription", {
                          attrs: { status: "Active" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      [
                        _c("PageCompaniesListSubscription", {
                          attrs: { status: "Expired" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }